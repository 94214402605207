<template>
  <div class="swiperBox" @mouseenter="mouseenter" @mouseleave="mouseleave">
    <!-- <div @click="moveImg('left')" class="imgLeft">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div  @click="moveImg('right')" class="imgRight">
      <i class="el-icon-arrow-right"></i>
    </div> -->
    <div id="swiper">
      <div class="imgBox">
        <div class="imgDiv" v-for="(item,index) of imgList" :key="index">
          <img :src="item" :style="'width:' + imgWidth + 'px;'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseSwiper',
  props: {
    speed: Number,
    direction: String,
    imgList: Array,
    imgWidth: Number
  },
  data() {
    return {
      timer: null,
      theSpeed: this.speed,
      theDirection: this.direction,
    }
  },
  mounted() {
    let imgDiv = document.getElementsByClassName('imgDiv');
    let imgBox = document.getElementsByClassName('imgBox')[0];
    let container = document.getElementsByClassName('container')[0];
    //将imgBox下的图片进行拼接 循环展示图片
    imgBox.innerHTML += imgBox.innerHTML;
    if(container.offsetWidth > imgBox.offsetWidth / 2) {
      imgBox.innerHTML += imgBox.innerHTML;
    }
    imgBox.style.width = imgDiv.length * (this.imgWidth + 15) + 'px';//设置div的宽度使图片可以放下
    this.autoScroll();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    mouseenter() {
      clearInterval(this.timer);
    },
    mouseleave() {
      this.autoScroll();
    },
    autoScroll() {
      let imgBox = document.getElementsByClassName('imgBox')[0];
      let container = document.getElementsByClassName('container')[0];
      let self = this;
      function autoScroll() {
        if (imgBox.offsetLeft < -(imgBox.offsetWidth - container.offsetWidth + 30) ) {//提前更新left值，实现循环展示
          imgBox.style.left = -(self.imgWidth + 30) + 'px';
        }
        // if (imgBox.offsetLeft > 0) {//向右滚动 提前更新left值，实现循环展示
        //   imgBox.style.left = -(imgBox.offsetWidth / 2) + 'px';
        // }
        self.theSpeed = -Math.abs(self.theSpeed)
        // 求出总的left值，等于left值加上移动的速度（px值）
        imgBox.style.left = imgBox.offsetLeft + self.theSpeed + 'px';
      }
      this.timer = setInterval(autoScroll, 30);//全局变量 ，保存返回的定时器
    },
  }
}
</script>

<style lang="scss" scoped>
.swiperBox {
  height: 100%;
  width: 100%;
  position: relative;
  .imgLeft {
    left: 0;
    top: 40%;
  }
  .imgLeft,
  .imgRight {
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #d2d2d2;
    color: #d2d2d2;
    border-radius: 100%;
    box-shadow: 0px 0px 9.8px 0.2px rgb(0 0 0 / 5%);
    font-size: 24px;
    left: auto;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    &:hover {
      border-color: #fed100;
      background-color: #fed100;
      color: #ffffff;
    }
  }
  .imgRight {
    right: 0;
    top: 40%;
  }
  .directionIcon:hover {
    opacity: 1;
  }
  #swiper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .imgBox {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      display: flex;
      transition: transform 2000ms ease 0s;
      .imgDiv {
        width: 100%;
        margin-left: 15px;
        img {
          height: 100%;
          width: 280px;
          // width: 260px;
          // height: 120px;
        }
      }
    }
  }
}
</style>