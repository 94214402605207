<template>
  <el-row :gutter="30">
    <el-col :md="11">
      <div class="big-img">
        <el-image v-if="productData.imgs.length" :src="productData.imgs[imgIndex]" fit="contain">
          <div slot="error" class="image-slot">
            <img src="../../assets/images/empty-img.jpg" alt="">
          </div>
        </el-image>
      </div>
      <div v-if="productData.imgs.length > 1" class="product-switch">
        <slider-img ref="sliderImg" @toggleImg="changeImg" :imgList="productData.imgs"></slider-img>
      </div>
    </el-col>
    <el-col :md="13">
      <div class="sp-heading">{{ productData.name }}<br>{{ productData.no }}</div>
      <div class="sp-price-box">
        <span class="new-price">${{ $utils.keepTwoDecimalFull(productData.foreignPrice) }}</span>
        <!-- <span class="old-price">$100.00</span> -->
      </div>
      <div class="features">See all features</div>
      <div class="quantity-area">
        <div class="quantity">
          <label>Quantity</label>
          <el-input-number v-model="quantity" :disabled="isDiscount" controls-position="right" :min="1"  class="quantity-input"></el-input-number>
        </div>
        <!-- <div v-if="!isDiscount" @click="addToCart(productData.id)" class="quantity-add">Add To Cart</div> -->
      </div>
      <div class="short-desc">
        <el-descriptions :column="2" size="mini">
          <el-descriptions-item v-for="(item,index) in productData.EssentialDetails" :key="index" :label="item.name">{{ item.val }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="social-title">Share This Product</div>
      <div>
        <div @click="gotoShareWeb(item.url)" v-for="item in shareList" :key="item.title" class="social-item">
          <el-tooltip effect="dark" :content="item.title" placement="top">
            <i :class="['iconfont',item.iconName]"></i>
          </el-tooltip>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import sliderImg from '../../components/shop/sliderImg'
export default {
  components: {
    sliderImg
  },
  props: {
    isDiscount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      quantity: 1,
      productData: {
        imgs: []
      },
      imgIndex: 0,
      shareList: [
        {
          title: 'Facebook',
          iconName: 'icon-mianshulogo',
          url: 'https://www.facebook.com/p/BBmart-Auto-Parts-100082812802804/'
        },
        {
          title: 'Twitter',
          iconName: 'icon-twitter-square-fill',
          url: 'https://twitter.com/'
        },
        {
          title: 'Youtube',
          iconName: 'icon-Youtube-fill',
          url: 'https://www.youtube.com/@bbmartautoparts5963/featured'
        },
        {
          title: 'Google Plus',
          iconName: 'icon-googleplus-circle-f',
          url: 'https://www.plus.google.com/discover'
        },
        {
          title: 'Instagram',
          iconName: 'icon-instagram',
          url: 'https://www.instagram.com/bbmartautoparts/'
        }
      ]
    }
  },
  methods: {
    /** 重置数据 */
    resetData() {
      this.quantity = 1;
      this.productData = {
        imgs: []
      };
      this.imgIndex = 0;
      this.$refs.sliderImg.resetData();
    },
    /** 加入购物车 */
    addToCart(id) {
      let arr = [];
      if(window.localStorage.getItem('cartIdArr')) {
        arr = window.localStorage.getItem('cartIdArr').split(',')
      }
      let obj = {};
      if(window.localStorage.getItem('cartIdNum')) {
        obj = JSON.parse(window.localStorage.getItem('cartIdNum'));
      }
      if(arr.includes(id)) {
        obj[id].num = obj[id].num + this.quantity;
      }else {
        arr.push(id);
        obj[id] = {
          num: this.quantity
        };
        this.$store.commit('addCartCount');
      }
      window.localStorage.setItem('cartIdArr',arr.join());
      window.localStorage.setItem('cartIdNum',JSON.stringify(obj));
      this.$message.success('success')
    },
    /** 切换图片 */
    changeImg(index) {
      this.imgIndex = index;
    },
    /** 跳转url */
    gotoShareWeb(url) {
      window.open(url,"_blank");
    },
  }
}
</script>

<style lang="scss" scoped>
.big-img {
  width: 100%;
  padding-bottom: 100%;
  border: 1px solid #ebebeb;
  position: relative;
  .el-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .image-slot {
      img {
        width: 100%;
      }
    }
  }
}
.product-switch {
  margin: 30px 0;
}
.sp-heading {
  width: 100%;
  line-height: 1;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
}
.sp-price-box {
  font-size: 16px;
  color: #c0c0c0;
  padding-bottom: 10px;
  .new-price {
    font-size: 24px;
    font-weight: 600;
    color: #e01212;
    margin-right: 5px;
  }
  .old-price {
    text-decoration: line-through;
  }
}
.features {
  font-size: 15px;
  padding-bottom: 15px;
}
.quantity-area {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 30px;
  .quantity-input {
    width: 90px;
    margin-left: 5px;
    ::v-deep .el-input__inner:focus {
      border-color: #fed100;
    }
  }
  .quantity-add {
    width: 140px;
    height: 40px;
    line-height: 40px;
    display: block;
    text-align: center;
    border-radius: 22px;
    font-weight: 600;
    border: 2px solid #fed100;
    margin-left: 15px;
    cursor: pointer;
    &:hover {
      background: #fed100;
      border-color: #fed100;
      color: #ffffff !important;
    }
  }
}
.short-desc {
  border-bottom: 1px solid #ebebeb;
  padding: 25px 0 25px 0;
}
.social-title {
  width: 100%;
  line-height: 1;
  font-size: 18px;
  font-weight: 400;
  padding: 25px 0;
}
.social-item {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  display: inline-block;
  border: 1px solid #ebebeb;
  margin-right: 10px;
  cursor: pointer;
}
</style>