<template>
  <div>
    <shop-head :yellowType="true" currentPageName="HOME"></shop-head>
    <!-- bannerTop -->
    <div class="slider_area-3">
      <div class="container">
        <el-row :gutter="30" type="flex" style="flex-wrap: wrap;">
          <!-- <el-col :md="6" class="d-none d-lg-block"></el-col> -->
          <el-col :md="24"><!-- :md="18" -->
            <el-row :gutter="30" type="flex" justify="center" style="flex-wrap: wrap;">
              <el-col :md="16" :sm="16">
                <el-carousel :interval="5000">
                  <el-carousel-item>
                    <video src="https://cloud.video.alibaba.com/play/u/2153292369/p/1/e/6/t/1/d/hd/330872982045.mp4"
                      class="introduction-video" controls></video>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="shop-banner_area">
                      <div class="img-hover_effect">
                        <img src="../../assets/images/banner01.png" alt="">
                      </div>
                      <div class="banner-text">
                        <div class="text">The Brake</div>
                        <div class="text">System</div>
                        <router-link to="/shop">
                          <div class="btn-ps_center">Shop Now</div>
                        </router-link>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </el-col>
              <el-col :md="8" :sm="8" :xs="24">
                <div class="shop-banner_area scale">
                  <div class="img-hover_effect">
                    <img src="../../assets/images/banner02.png" alt="">
                  </div>
                  <div class="banner-content">
                    <div>The Brake System</div>
                    <router-link to="/shop">
                      <div class="btn-ps_center">Shop Now</div>
                    </router-link>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-if="categoriesList.length" class="product-categories">
      <div class="container">
        <div class="title">Shop by categories</div>
        <div class="product-categories-list">
          <el-row>
            <el-col v-for="item in categoriesList" :key="item.classId" :xs="8" :sm="6" :md="4">
              <img :src="item.image ? item.image : require('../../assets/images/empty-img.jpg')"
                class="product-categories-img">
              <div class="product-categories-title">{{ item.className }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- bannerCenter -->
    <div class="banner_area-5">
      <div class="container">
        <el-row :gutter="30" type="flex" style="flex-wrap: wrap;">
          <el-col :md="12" :sm="12">
            <div class="shop-banner_area scale">
              <div class="img-hover_effect">
                <img src="../../assets/images/proBanner01.png" alt="">
              </div>
              <div class="banner-text white small long">
                <div>ONE-STOP AUTO AFTERMARET</div>
                <div>SERVICE PROVIDER</div>
                <router-link to="/shop">
                  <div class="btn-ps_center red">Shop Now</div>
                </router-link>
              </div>
            </div>
          </el-col>
          <el-col :md="12" :sm="12">
            <div class="shop-banner_area scale">
              <div class="img-hover_effect">
                <img src="../../assets/images/proBanner02.png" alt="">
              </div>
              <div class="banner-text white small long">
                <div>ONE-STOP AUTO AFTERMARET</div>
                <div>SERVICE PROVIDER</div>
                <router-link to="/shop">
                  <div class="btn-ps_center red">Shop Now</div>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="shipping-nav">
      <div class="container">
        <div class=" shipping-box">
          <el-row :gutter="30" type="flex" style="flex-wrap: wrap;">
            <el-col :md="6" :sm="12" :xs="12">
              <div class="shipping-icon">
                <i class="iconfont icon-7"></i>
              </div>
              <div class="shipping-text">
                <div class="shipping-text-title">Free Shipping</div>
                <div>On all orders over $75.00</div>
              </div>
            </el-col>
            <el-col :md="6" :sm="12" :xs="12">
              <div class="shipping-icon">
                <i class="iconfont icon-shuaxin"></i>
              </div>
              <div class="shipping-text">
                <div class="shipping-text-title">Easy 30 Days Returns</div>
                <div>30 days money back</div>
              </div>
            </el-col>
            <el-col :md="6" :sm="12" :xs="12">
              <div class="shipping-icon">
                <i class="iconfont icon-xinyongka"></i>
              </div>
              <div class="shipping-text">
                <div class="shipping-text-title">100% Secure</div>
                <div>PayPal / MasterCard / Visa</div>
              </div>
            </el-col>
            <el-col :md="6" :sm="12" :xs="12">
              <div class="shipping-icon">
                <i class="iconfont icon-liferaft"></i>
              </div>
              <div class="shipping-text">
                <div class="shipping-text-title">24/7 Support</div>
                <div>We will be at your service</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- scrollProduct -->
    <div class="product-tab_area-2">
      <div class="container">
        <el-carousel :height="sellHighe" trigger="click" :autoplay="false" indicator-position="outside">
          <el-carousel-item v-for="item in discountList" :key="item.id">
            <el-row ref="singleProduct" :gutter="30" type="flex" class="single-product" style="flex-wrap: wrap;">
              <el-col :md="10" :sm="10">
                <div class="product-tab-additional_stuff">
                  <div class="heading">Deal of the days</div>
                  <div class="price-box">
                    <span class="new-price">$70.00</span>
                    <span class="old-price">$80.00</span>
                  </div>
                  <div class="product-name">Aliquet auctor semali</div>
                  <div class="rating-box">
                    <i v-for="item in 3" :key="item" class="iconfont icon-pingfendengjiRating1"></i>
                    <i class="iconfont icon-pingfendengjiRating7 gray"></i>
                    <i class="iconfont icon-shuyi_xing-pingfen gray"></i>
                  </div>
                  <div class="gray-box-flex">
                    <div class="text">Available: <i>369</i></div>
                    <div class="text">Unit Sold: <i>56</i></div>
                  </div>
                  <div class="gray-box-progress">
                    <div class="yellow"></div>
                  </div>
                  <div class="countdown_area">
                    <div class="heading">Deal ends in:</div>
                    <div class="countdown">
                      <div class="count">
                        <div class="count-amount">{{ countTimeData.Days }}</div>
                        <div class="count-period">Days</div>
                      </div>
                      <div class="count">
                        <div class="count-amount">{{ countTimeData.Hrs }}</div>
                        <div class="count-period">Hrs</div>
                      </div>
                      <div class="count">
                        <div class="count-amount">{{ countTimeData.Mins }}</div>
                        <div class="count-period">Mins</div>
                      </div>
                      <div class="count">
                        <div class="count-amount">{{ countTimeData.Secs }}</div>
                        <div class="count-period">Secs</div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :md="10" :sm="10">
                <div @click="gotoDetail(item.id)" class="product-img">
                  <img :src="item.urlList.length ? item.urlList[item.currentIndex].big : ''" alt="">
                  <div class="add-action">
                    <ul>
                      <!-- <li @click.stop="addToCart(item.id,'discount')">
                        <el-tooltip effect="dark" content="Add To Cart" placement="top">
                          <i class="el-icon-goods"></i>
                        </el-tooltip>
                      </li> -->
                      <!-- <li @click="showQuickView">
                        <el-tooltip effect="dark" content="Add To Wishlist" placement="top">
                          <i class="el-icon-star-off"></i>
                        </el-tooltip>
                      </li> -->
                      <li @click.stop="showQuickView(item,true)">
                        <el-tooltip effect="dark" content="Quick View" placement="top">
                          <i class="el-icon-search"></i>
                        </el-tooltip>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-col>
              <el-col :md="4" :sm="4">
                <div class="product-tab_wrap">
                  <div class="product-tab">
                    <ul class="nav">
                      <li @click="changeDiscountItemIndex(i,item)" v-show="i < 4" v-for="(val,i) in item.urlList"
                        :key="i" :class="{'active': i === item.currentIndex}">
                        <img :src="val.big" alt="">
                      </li>
                    </ul>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="product-tab_area">
      <div class="product-tab_nav">
        <div class="container">
          <div class="product-tab">
            <ul>
              <li @click="switchPartTab(index)" v-for="(item,index) in partTab" :key="index"
                :class="{'active': index === partTabIndex}">{{ item.name }}</li>
            </ul>
          </div>
          <div class="product-box">
            <div @click="gotoDetail(item.id)" v-for="item in partTab[partTabIndex].children" :key="item.id"
              class="part-item">
              <div class="product-img">
                <img :src="item.urlList.length ? item.urlList[0].big : ''" alt="">
                <div class="add-action">
                  <ul>
                    <!-- <li @click.stop="addToCart(item.id)">
                        <el-tooltip effect="dark" content="Add To Cart" placement="top">
                          <i class="el-icon-goods"></i>
                        </el-tooltip>
                      </li> -->
                    <!-- <li @click="showQuickView">
                        <el-tooltip effect="dark" content="Add To Wishlist" placement="top">
                          <i class="el-icon-star-off"></i>
                        </el-tooltip>
                      </li> -->
                    <li @click.stop="showQuickView(item)">
                      <el-tooltip effect="dark" content="Quick View" placement="top">
                        <i class="el-icon-search"></i>
                      </el-tooltip>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="part-item-price">${{ $utils.keepTwoDecimalFull(item.price) }}</div>
              <div class="part-item-text" :title="item.name">{{ item.name }}</div>
              <div class="part-item-star">
                <i v-for="item in 3" :key="item.id" class="iconfont icon-pingfendengjiRating1"></i>
                <i class="iconfont icon-pingfendengjiRating7 gray"></i>
                <i class="iconfont icon-shuyi_xing-pingfen gray"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="full-banner">
      <img src="../../assets/images/fullBanner.png" alt="">
    </div>
    <div class="product-tab_area">
      <div class="container">
        <el-row :gutter="30" type="flex" style="flex-wrap: wrap;" class="section_area">
          <el-col v-for="item in partSeries" :key="item.title" :md="8">
            <div class="section_title">{{ item.title }}</div>
            <el-row :gutter="30" type="flex" style="flex-wrap: wrap;">
              <el-col v-for="(val,index) in item.children" :key="index" :md="24" :sm="12">
                <div class="section_title-product">
                  <img class="section_img" :src="val.urlList.length ? val.urlList[0].big : ''" alt="">
                  <div class="section_product-content">
                    <div class="price-box">
                      <span class="new-price">${{ $utils.keepTwoDecimalFull(val.price)}}</span>
                      <!-- <span class="old-price">${{ val.price }}</span> -->
                    </div>
                    <div class="section_name" :title="val.name">{{ val.name }}</div>
                    <div class="section_star">
                      <i v-for="item in 3" :key="item" class="iconfont icon-pingfendengjiRating1"></i>
                      <i class="iconfont icon-pingfendengjiRating7 gray"></i>
                      <i class="iconfont icon-shuyi_xing-pingfen gray"></i>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="container">
      <div class="swiper-box">
        <swiper :imgList="imgList" :imgWidth="124" :speed="1" :direction="'left'"></swiper>
      </div>
    </div>
    <!-- iconBlock -->
    <div class="shipping_area">
      <div class="container">
        <div class="icon-box">
          <div class="icon-box-item">
            <img src="../../assets/images/icon01.png" alt="40,000km warranty">
            <div class="icon-box-text">40,000km warranty</div>
          </div>
          <div class="icon-box-item">
            <img src="../../assets/images/icon02.png" alt="300,000+SKU">
            <div class="icon-box-text">300,000+SKU</div>
          </div>
          <div class="icon-box-item">
            <img src="../../assets/images/icon03.png" alt="40,000+in stock">
            <div class="icon-box-text">40,000+in stock</div>
          </div>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
    <el-dialog :visible.sync="showDetail" width="80%">
      <quick-view ref="quickView"></quick-view>
    </el-dialog>
  </div>
</template>

<script>
  import quickView from '../../components/shop/quickView'
  import shopHead from '../../components/shop/head'
  import commonFooter from '../../components/common/footer'
  import swiper from '../../components/shop/swiper'
  export default {
    components: {
      quickView,
      shopHead,
      commonFooter,
      swiper
    },
    data() {
      return {
        showDetail: false,
        partTab: [{
            name: 'Oil Filters',
            children: []
          },
          {
            name: 'Air Filters',
            children: []
          },
          {
            name: 'Ignition Coils',
            children: []
          }
        ],
        partTabIndex: 0,
        partSeries: [{
            title: 'Other Auto Steering Parts',
            children: []
          },
          {
            title: 'Radiators',
            children: []
          },
          {
            title: 'Brake Discs',
            children: []
          }
        ],
        imgList: [
          require('../../assets/images/partIcon01.png'),
          require('../../assets/images/partIcon02.png'),
          require('../../assets/images/partIcon03.png'),
          require('../../assets/images/partIcon04.png'),
          require('../../assets/images/partIcon05.png'),
          require('../../assets/images/partIcon06.png')
        ],
        discountList: [],
        isDiscount: false,
        sellHighe: '500px',
        timer: null,
        countTimeData: {
          Days: '00',
          Hrs: '00',
          Mins: '00',
          Secs: '00'
        },
        categoriesList: []
      }
    },
    watch: {
      showDetail(val) {
        if (!val) {
          this.$nextTick(() => {
            this.$refs.quickView.resetData();
          })
        }
      }
    },
    created() {
      this.initData();
    },
    mounted() {
      this.getCategoriesList();
      let timer = setTimeout(() => {
        this.sellHighe = this.$refs.singleProduct[0].$el.offsetHeight + 'px';
        clearTimeout(timer);
      }, 500)
      window.addEventListener('resize', this.resizeWindow);
      this.countTime();
    },
    destroyed() {
      window.removeEventListener('resize', this.resizeWindow);
      clearInterval(this.timer)
    },
    methods: {
      /** 获取分类列表 */
      getCategoriesList() {
        this.$axios.post('/foreign/product/findAllProductClasses', {}).then((res) => {
          if (res.successful) {
            this.categoriesList = res.resultSet.slice(0, 18);
          }
        });
      },
      countTime() {
        //获取当前时间  
        let date = new Date();
        let now = date.getTime();

        //设置截止时间  
        let tomorrow = now + 1000 * 60 * 60 * 24;
        tomorrow = new Date(tomorrow);
        let str = `${tomorrow.getFullYear()}/${tomorrow.getMonth() + 1}/${tomorrow.getDate()} 00:00:00`;
        let endDate = new Date(str);
        let end = endDate.getTime();
        //时间差  
        let leftTime = end - now;
        //定义变量 d,h,m,s保存倒计时的时间  
        let d, h, m, s;
        if (leftTime >= 0) {
          d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
          h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
          m = Math.floor(leftTime / 1000 / 60 % 60);
          s = Math.floor(leftTime / 1000 % 60);
        } else {
          clearInterval(this.timer);
          return;
        }
        //将0-9的数字前面加上0，例1变为01
        this.countTimeData = {
          Days: d < 10 ? '0' + d : d,
          Hrs: h < 10 ? '0' + h : h,
          Mins: m < 10 ? '0' + m : m,
          Secs: s < 10 ? '0' + s : s
        }
        //递归每秒调用countTime方法，显示动态时间效果  
        this.timer = setTimeout(this.countTime, 1000);
      },
      /** 监听窗口变化 */
      resizeWindow() {
        this.sellHighe = this.$refs.singleProduct[0].$el.offsetHeight + 'px';
      },
      /** 快速查看 */
      showQuickView(item, isDiscount = false) {
        this.showDetail = true;
        this.isDiscount = isDiscount;
        this.$nextTick(() => {
          this.$refs.quickView.productData = item;
        })
      },
      /** 跳转详情 */
      gotoDetail(id) {
        this.$router.push({
          path: '/shopDetail',
          query: {
            id: id
          }
        })
      },
      /** 加入购物车 */
      addToCart(id, type) {
        // if(type === 'discount') {
        //   this.$message.warning('end of activity');
        //   return;
        // }
        let arr = [];
        if (window.localStorage.getItem('cartIdArr')) {
          arr = window.localStorage.getItem('cartIdArr').split(',')
        }
        let obj = {};
        if (window.localStorage.getItem('cartIdNum')) {
          obj = JSON.parse(window.localStorage.getItem('cartIdNum'));
        }
        if (arr.includes(id)) {
          obj[id].num++;
        } else {
          arr.push(id);
          obj[id] = {
            num: 1
          };
          this.$store.commit('addCartCount');
        }
        window.localStorage.setItem('cartIdArr', arr.join());
        window.localStorage.setItem('cartIdNum', JSON.stringify(obj));
        this.$message.success('success')
      },
      /** 切换折扣商品小图 */
      changeDiscountItemIndex(index, item) {
        item.currentIndex = index;
      },
      /** 初始化各个模块的数据 */
      initData() {
        let arr = JSON.parse(JSON.stringify(this.$productList.productList));
        arr.forEach(item => {
          if (item.categoryName === 'Oil Filters') {
            this.partTab[0].children.push(item);
          } else if (item.categoryName === 'Air Filters') {
            this.partTab[1].children.push(item);
          } else if (item.categoryName === 'Ignition Coils') {
            this.partTab[2].children.push(item);
          } else if (item.categoryName === 'Other Auto Steering Parts') {
            this.partSeries[0].children.push(item);
          } else if (item.categoryName === 'Radiators') {
            this.partSeries[1].children.push(item);
          } else if (item.categoryName === 'Brake Discs') {
            this.partSeries[2].children.push(item);
          } else if (item.categoryName === 'Other Auto Parts') {
            let obj = JSON.parse(JSON.stringify(item));
            obj.currentIndex = 0;
            this.discountList.push(obj);
          }
        })
      },
      /** 切换tab */
      switchPartTab(index) {
        this.partTabIndex = index;
      }
    }
  }
</script>

<style lang="scss" scoped>
  /** banner */
  .shop-banner_area {
    width: 100%;
    padding-bottom: 25px;
    position: relative;

    .img-hover_effect {
      width: 100%;
      position: relative;
      display: block;
      overflow: hidden;

      img {
        width: 100%;
        transform: scale(1);
        transition: all 0.3s ease-in;
        vertical-align: middle;
      }
    }

    &.scale:hover {
      .img-hover_effect {
        img {
          transform: scale(1.06);
          transition: all 0.3s ease-in;
        }
      }
    }

    .banner-text {
      width: 50%;
      line-height: 1;
      font-size: 37px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;

      &.small {
        font-size: 18px;
      }

      &.white {
        color: #fff;
      }

      &.long {
        width: 80%;
      }

      .text {
        font-weight: bold;
      }

      .btn-ps_center {
        width: 135px;
        height: 40px;
        line-height: 38px;
        font-size: 15px;
        color: #ffffff;
        text-align: center;
        font-weight: 600;
        border: 0;
        border-radius: 20px;
        background: #fed100;
        margin-top: 25px;

        &.red {
          background: #E01212;
        }
      }
    }

    .banner-content {
      width: 90%;
      line-height: 1;
      font-size: 20px;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .btn-ps_center {
        width: 135px;
        height: 40px;
        line-height: 38px;
        font-size: 15px;
        color: #ffffff;
        font-weight: 600;
        border: 0;
        border-radius: 20px;
        background: #fed100;
        margin: 25px auto 0;
      }
    }
  }

  .slider_area-3 {
    background-color: #ffffff;
    padding-top: 30px;

    ::v-deep.el-carousel__container {
      height: auto;
      padding-bottom: 75.44%;

      .introduction-video {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .shipping_area {
    padding-bottom: 60px;

    .icon-box {
      width: 100%;
      height: auto;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border: 1px solid #F4F4F4;
      padding: 40px 36px;

      .icon-box-item {
        max-width: 33.33%;
        line-height: 20px;
        font-size: 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;

        img {
          flex: 0 0 40px;
          width: 40px;
          height: 40px;
        }

        .icon-box-text {
          flex: 1;
          padding-left: 5px;
          word-break: break-all;
        }
      }

      @media only screen and (max-width: 767px) {
        align-items: flex-start;
        padding: 20px 15px;

        .icon-box-item {
          display: inline-block;

          img {
            flex: unset;
            margin: 0 auto;
            display: block;
          }

          .icon-box-text {
            flex: unset;
            padding-left: 0;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  .shipping-nav {
    padding-top: 60px;

    .shipping-box {
      width: 100%;
      height: auto;
      border: 1px solid #ebebeb;
      padding: 30px 0 25px;

      .el-col {
        display: flex;
        align-items: center;

        .shipping-icon {
          flex: 0 0 60px;
          height: 60px;
          line-height: 60px;
          color: #fed100;
          text-align: center;

          .iconfont {
            font-size: 40px;
          }
        }

        .shipping-text {
          flex: 1;
          font-size: 14px;
          color: #333;
          padding-left: 15px;

          .shipping-text-title {
            line-height: 1;
            font-size: 16px;
            font-weight: 400;
            padding-bottom: 10px;
          }
        }
      }

      @media only screen and (max-width: 991px) {
        padding: 30px 30px 25px;
      }

      @media only screen and (max-width: 767px) {
        .el-col {
          display: unset;

          .shipping-icon {
            flex: unset;
            width: 60px;
            margin: 0 auto;
          }

          .shipping-text {
            flex: unset;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  .product-tab_area-2 {
    padding-top: 60px;

    // ::v-deep .el-carousel__container {
    //   height: 600px;
    //   @media only screen and (max-width: 767px) {
    //     height: 1120px
    //   }
    // }
    .single-product {
      .product-tab-additional_stuff {
        background-color: #f4f4f4;
        padding: 55px 30px 60px;
        text-align: center;

        .heading {
          font-size: 28px;
          line-height: 1;
          margin-bottom: 0;
          position: relative;
          padding-bottom: 45px;

          &::before {
            content: "";
            background-color: #fed100;
            position: absolute;
            bottom: 30px;
            height: 6px;
            width: 60px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 3px;
          }
        }

        .price-box {
          padding-bottom: 10px;

          .new-price {
            color: #e01212;
            font-size: 22px;
            font-weight: 600;
          }

          .old-price {
            color: #c0c0c0;
            text-decoration: line-through;
            font-size: 16px;
            display: inline-block;
          }
        }

        .product-name {
          line-height: 1;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .rating-box {
          color: #fed100;

          .iconfont {
            font-size: 12px;
          }

          .gray {
            color: #c0c0c0;
          }
        }

        .gray-box-flex {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: space-between;

          .text {
            font-size: 15px;

            i {
              font-weight: bold;
              font-style: normal;
            }
          }
        }

        .gray-box-progress {
          width: 100%;
          height: 15px;
          background: #EBEBEB;
          border-radius: 8px;
          position: relative;
          margin: 5px 0 25px;

          .yellow {
            width: 85%;
            height: 15px;
            background: #FED100;
            border-radius: 8px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        .countdown_area {
          .heading {
            margin-bottom: 0;
            font-size: 16px;
            padding-bottom: 25px;

            &::before {
              display: none;
            }
          }

          .countdown {
            border: 2px solid #fed100;
            border-radius: 50px;
            text-align: center;
            background: #fff;

            .count {
              width: 22%;
              padding: 5px 0;
              display: inline-block;
              position: relative;

              .count-amount {
                font-size: 16px;
                font-weight: 600;
              }

              .count-period {
                font-size: 14px;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .product-img {
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
        }

        .add-action {
          text-align: center;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;

          li {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            text-align: center;
            opacity: 0;
            background-color: #fed100;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            transition: all 0.3s ease-in;

            &:nth-child(1) {
              transition-delay: 100ms;
            }

            &:nth-child(2) {
              transition-delay: 150ms;
            }

            &:nth-child(3) {
              transition-delay: 200ms;
            }
          }
        }
      }

      &:hover {
        border-color: #fed100;

        .add-action {
          li {
            opacity: 1;
          }
        }
      }

      .product-tab_wrap {
        display: flex;
        justify-content: flex-end;

        .product-tab {
          .nav {
            li {
              margin-bottom: 15px;
              cursor: pointer;

              img {
                width: 100%;
                max-width: 124px;
                border: 2px solid #ebebeb;
              }

              &.active {
                img {
                  border-color: #fed100;
                }
              }
            }
          }

          @media only screen and (max-width: 768px) {
            padding-top: 30px;

            .nav {
              display: flex;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .product-tab_area {
    padding-top: 60px;

    .product-tab_nav {
      .product-tab {
        padding-bottom: 30px;

        ul {
          display: flex;
          justify-content: center;

          li {
            font-weight: 400;
            padding: 0 25px 15px;
            position: relative;
            margin-right: 15px;
            display: block;
            font-size: 28px;
            text-align: center;

            &.active {
              font-weight: 600;

              &::before {
                content: "";
                background-color: #fed100;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 6px;
                width: 48px;
                border-radius: 3px;
              }
            }
          }

          @media only screen and (max-width: 767px) {
            padding-bottom: 25px;

            li {
              margin-right: 0;
              padding: 0 25px 0 0;
              font-size: 18px;

              &.active::before {
                display: none;
              }
            }
          }

          @media only screen and (max-width: 479px) {
            li {
              padding: 0 10px 15px 0;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .product-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .part-item {
      width: 100%;
      height: auto;
      border: 1px solid #F4F4F4;
      box-sizing: border-box;
      padding: 30px 10px;
      margin: 0 0 29px;

      @media only screen and (min-width: 576px) {
        width: 49%;
        margin: 0 0.5% 29px;
      }

      @media only screen and (min-width: 768px) {
        width: 32.33%;
        margin: 0 0.5% 29px;
      }

      @media only screen and (min-width: 992px) {
        width: 24%;
        margin: 0 0.5% 29px;
      }

      @media only screen and (min-width: 1200px) {
        width: 19%;
        margin: 0 0.5% 29px;
      }

      .product-img {
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          margin: 0 auto 25px;
        }

        .add-action {
          text-align: center;
          position: absolute;
          bottom: 29px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;

          li {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            text-align: center;
            opacity: 0;
            background-color: #fed100;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            transition: all 0.3s ease-in;

            &:nth-child(1) {
              transition-delay: 100ms;
            }

            &:nth-child(2) {
              transition-delay: 150ms;
            }

            &:nth-child(3) {
              transition-delay: 200ms;
            }
          }
        }
      }

      &:hover {
        border-color: #fed100;

        .add-action {
          li {
            opacity: 1;
          }
        }
      }
    }

    .part-item-price {
      width: 100%;
      line-height: 1;
      font-size: 22px;
      color: #E01212;
      font-weight: 600;
      padding-bottom: 15px;
      box-sizing: border-box;

      span {
        font-size: 16px;
        font-weight: normal;
        color: #C0C0C0;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }

    .part-item-text {
      width: 100%;
      line-height: 24px;
      font-size: 15px;
      padding-bottom: 5px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /*超出2行就显示省略号，可以填3或者其它正整数*/
    }

    .part-item-star {
      width: 100%;
      line-height: 24px;
      font-size: 12px;
      color: #FED100;
      padding-bottom: 10px;
      box-sizing: border-box;

      .gray {
        color: #C0C0C0;
      }
    }
  }

  .full-banner {
    width: 100%;
    margin-top: 31px;
    position: relative;

    img {
      width: 100%;
      display: block;
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      width: 50%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: #161E2D;
    }

    &::after {
      content: '';
      width: 50%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background: #DFB803;
    }
  }

  .section_area {
    .section_title {
      line-height: 1;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      padding-bottom: 15px;
      padding-top: 25px;
      margin-bottom: 30px;
      position: relative;

      &::before {
        background-color: #fed100;
        content: "";
        position: absolute;
        bottom: 0;
        height: 6px;
        width: 60px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 3px;
      }
    }

    .section_title-product {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      border: 1px solid #F4F4F4;
      padding: 20px 0 10px;
    }

    .section_img {
      width: 130px;
      flex: 0 0 130px;
    }

    .section_product-content {
      flex: 1;
      padding-left: 15px;

      .price-box {
        padding-bottom: 10px;

        .new-price {
          font-size: 16px;
          color: #e01212;
          font-weight: 600;
        }

        .old-price {
          font-size: 15px;
          font-weight: 400;
          color: #c0c0c0;
          text-decoration: line-through;
        }
      }

      .section_name {
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /*超出2行就显示省略号，可以填3或者其它正整数*/
      }

      .section_star {
        width: 100%;
        color: #FED100;
        padding-bottom: 10px;
        box-sizing: border-box;

        .iconfont {
          font-size: 12px;
        }

        .gray {
          color: #C0C0C0;
        }
      }
    }
  }

  .swiper-box {
    width: 100%;
    height: 141px;
    border: 1px solid #ebebeb;
    padding: 30px 0;
    margin-bottom: 60px;
  }

  .product-categories {
    .title {
      width: 100%;
      line-height: 50px;
      font-size: 20px;
      font-weight: bold;
    }

    .product-categories-list {
      width: 100%;
      height: auto;
      padding: 20px 0 40px;

      .el-col {
        margin-bottom: 30px;
      }

      .product-categories-img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        object-fit: contain;
        border: 1px solid #eeeeee;

        @media only screen and (max-width: 1200px) {
          width: 140px;
          height: 140px;
        }

        @media only screen and (max-width: 992px) {
          width: 130px;
          height: 130px;
        }

        @media only screen and (max-width: 768px) {
          width: 100px;
          height: 100px;
        }
      }

      .product-categories-title {
        width: 100%;
        line-height: 20px;
        font-size: 16px;
        text-align: center;
        padding-top: 10px;
      }
    }
  }
</style>