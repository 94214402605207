<template>
  <div>
    <div ref="sliderBox" class="slider-box">
      <div @click="clickArrow('left')" class="arrow">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div ref="box" class="slider-box-in">
        <div ref="sliderImg" class="slider-img-box">
          <div @click="chooseImg(index)" v-for="(item,index) in imgList" :key="index" :class="{'img-item': true, 'active': index === activeImg}">
            <img ref="img" :src="item" alt="">
          </div>
        </div>
      </div>
      <div @click="clickArrow" class="arrow">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgList: {
      type: Array,
      default: () => {
        return [];
      }
    }  
  },
  data() {
    return {
      imgHeight: 0,
      count: 0,
      activeImg: 0
    }
  },
  methods: {
    /** 重置数据 */
    resetData() {
      this.imgHeight = 0;
      this.count = 0;
      this.activeImg = 0;
    },
    /** 选择图片 */
    chooseImg(index) {
      this.activeImg = index;
      this.$emit('toggleImg',index)
    },
    /** 切换箭头 */
    clickArrow(type) {
      let last = Math.ceil((this.$refs.img[0].offsetWidth + 10) * this.imgList.length / this.$refs.sliderImg.offsetWidth);
      if(type === 'left') {
        if(this.count > 0) {
          let distance = -(this.$refs.sliderImg.offsetWidth/4)*(this.count - 1);
          this.count--;
          this.$refs.sliderImg.style.transform = 'translate3d(' + distance + 'px, 0px, 0px)';
        } 
      }else {
        if(this.count <= (last - 1)) {
          this.count++;
          let distance = -(this.$refs.sliderImg.offsetWidth/4)*this.count;
          this.$refs.sliderImg.style.transform = 'translate3d(' + distance + 'px, 0px, 0px)';
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-box {
  width: 100%;
  display: flex;
  align-items: center;
  .arrow {
    flex: 0 0 20px;
    text-align: center;
  }
  .slider-box-in {
    white-space: nowrap;
    flex: 1;
    overflow: hidden;
    .slider-img-box {
      transform: translate3d(0px, 0px, 0px);
      transition: transform 500ms ease 0s;
      .img-item {
        width: 24%;
        margin: 0 0.5%;
        border: 1px solid #eee;
        padding-bottom: 24%;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        position: relative;
        &.active {
          border-color: #fed100;
        }
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          object-fit: contain;
        }
      }
    }
  }
  
}
</style>